import React from 'react';
import styled, {withTheme} from 'styled-components';
import HeaderText from '../Text/HeaderText/HeaderText';
import CloseIcon from '../CloseIcon/CloseIcon';

const Wrapper = styled.div`
  background: ${(props) =>
    props.theme.components.detailsNavigation?.bgColor ||
    props.theme.colors.body ||
    props.theme.colors.primary};
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 8px 10px;
  min-height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavIcon = styled.div<{right?: boolean}>`
  position: absolute;
  left: 14px;
  fill: ${(props) =>
    props.theme.components.detailsNavigation?.iconColor ||
    props.theme.components.detailsNavigation?.color ||
    props.theme.colors.white};
  cursor: pointer;
  width: 25px;
`;

const Text = styled(HeaderText)`
  display: block;
  text-align: center;
  color: ${(props) =>
    props.theme.components.detailsNavigation?.color ||
    props.theme.colors.white};
  font-family: ${(props) => props.theme.font.secondary.name};
  font-weight: ${(props) =>
    props.theme.components.detailsNavigation?.fontWeight ||
    props.theme.font.styles.detailsHeader.fontWeight};
  padding: 2px 25px;
`;

const DetailsNavigation = (props: any) => {
  return (
    <Wrapper>
      <NavIcon onClick={props.onClose}>
        <CloseIcon iconType={'times'} />
      </NavIcon>
      <Text>{props.text}</Text>
    </Wrapper>
  );
};

export default withTheme(DetailsNavigation);
